import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { Keys } from "../pages/Linq/authorizedKeys";

export interface InitialStateDataProps {
  linq_enabled_orgs: ICustomerInfo[];
  roles: string[];
  selectedCustomerInfo: ICustomerInfo | null;
  userInfo: IUserInfo | null;
  showOnPremInstaller: boolean;
  globalToasts: any[];
  selectedGateway: string;
  pendingRequests: any[];
  isUserLoggedIn: boolean;
  keys?: {
    customerId:any;
    keys:Keys
  }[];
  authorizedKeyReferences ?: any ; 
  showGatewayUpgradeModal: boolean;
  isSessionExpired: boolean;
}

interface ICustomerInfo {
  customerId: number;
  customerName: string;
}

export interface IUserInfo {
  email: string;
  acceptedTOS?: boolean;
}

const initialState: InitialStateDataProps = {
  linq_enabled_orgs: [],
  roles: [],
  selectedCustomerInfo: null,
  userInfo: null,
  showOnPremInstaller: false,
  globalToasts: [],
  selectedGateway: "",
  pendingRequests: [],
  isUserLoggedIn: false,
  keys: [],
  showGatewayUpgradeModal: false,
  isSessionExpired: false,
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setLoginInfo: (state, action: PayloadAction<InitialStateDataProps>) => {
      const {
        linq_enabled_orgs,
        roles,
        userInfo,
        showOnPremInstaller,
        pendingRequests,
        authorizedKeyReferences,
      } = action.payload;
      state.roles = roles;
      state.linq_enabled_orgs = linq_enabled_orgs;
      state.userInfo = userInfo;
      state.selectedCustomerInfo = null;
      state.showOnPremInstaller = showOnPremInstaller;
      state.pendingRequests = pendingRequests;
      state.isUserLoggedIn = true;
      state.keys = authorizedKeyReferences && authorizedKeyReferences?.length>=1 && authorizedKeyReferences || [];
    },
    setSelectedCustomerInfo: (state, action: PayloadAction<ICustomerInfo>) => {
      state.selectedCustomerInfo = action.payload;
    },
    setUserLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isUserLoggedIn = action.payload;
    },
    logOut: (state) => {
      state.roles = [];
      state.linq_enabled_orgs = [];
      state.selectedCustomerInfo = null;
      state.showOnPremInstaller = false;
      state.isUserLoggedIn = false;
      state.keys = [];
    },
    setGlobalToasts: (state, action: PayloadAction<any>) => {
      state.globalToasts = action.payload;
    },
    setSelectedGateway: (state, action: PayloadAction<string>) => {
      state.selectedGateway = action.payload;
    },
    resetLoginInfo: (state) => {
      state.linq_enabled_orgs = [];
      state.roles = [];
      state.globalToasts = [];
      state.selectedCustomerInfo = null;
      state.userInfo = null;
      state.showOnPremInstaller = false;
      state.selectedGateway = "";
      state.pendingRequests = [];
      state.isUserLoggedIn = false;
      state.keys = [];
    },
    setPendingRequests: (state, action: PayloadAction<any>) => {
      state.pendingRequests = action.payload;
    },
    setShowGatewayUpgradeModal: (state, action: PayloadAction<any>) => {
      state.showGatewayUpgradeModal = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<IUserInfo>) => {
      return { ...state, userInfo: action.payload };
    },
    setIsSessionExpired: (state, action: PayloadAction<boolean>) => {
      return { ...state, isSessionExpired: action.payload };
    },
  },
});

export default userInfoSlice.reducer;
export const {
  setLoginInfo,
  setSelectedCustomerInfo,
  logOut,
  setGlobalToasts,
  setSelectedGateway,
  resetLoginInfo,
  setPendingRequests,
  setUserInfo,
  setShowGatewayUpgradeModal,
  setIsSessionExpired
} = userInfoSlice.actions;
export const loginInfoData = (state: RootState) => state.loginInfo;
