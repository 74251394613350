import { Card,  Tabs, Typography } from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../utils/i18nUtils";
import AuthorizedKeyCard from "./AuthorizedKeyCard";
import { useState } from "react";
import store from "../../../store/store";
import { isCustomKey, isICEKey, isMOBKey } from "./helper";
import { useSelector } from "react-redux";
import { loginInfoData } from "../../../reducers/userInfoReducer";

export interface Keys {
  reference: string,
  friendlyName?: string,
  issuedOn?: string,
  expiresOn?: string,
  endpointId?: number,
  ecpFrame?: string,
  legacyFrame?: any,
  configCardEnablement?: boolean
}


export enum renderAuthorizedKey {
  ALLKEYS = "allKeys",
  ICEKEYS = "iceKeys",
  MOBKEYS = "mobKeys",
  CUSTOMKEYS = "customKeys"
}

interface TabItem {
  key: renderAuthorizedKey;
  label: string;
  content: React.ReactElement;
}

const AuthorizedKey = () => {
  const { loginInfo } = store.getState();
  const {keys:authorizedKeyData = []} = loginInfo;
  const { t } = useTranslation(NAMESPACE.READER);
   const {
      selectedCustomerInfo
    } = useSelector(loginInfoData);
  const customerId = selectedCustomerInfo?.customerId?.toString();
  const organizationBasedKeys:any =  authorizedKeyData?.filter((organization:any)=>organization?.customerId == customerId);
  const [keysData , setKeysData] = useState<Keys[]>(organizationBasedKeys?.length==1 ? [...organizationBasedKeys?.[0]?.keys]?.sort((a: Keys, b: Keys) =>
    a?.reference.localeCompare(b?.reference)
  ) : []);


  const filterKeysHandler = (k:string) => {
    let filteredData : any = [...organizationBasedKeys?.[0]?.keys]; 

    const filterFunctions:any = {
      [renderAuthorizedKey.ICEKEYS] : isICEKey,
      [renderAuthorizedKey.MOBKEYS] : isMOBKey,
      [renderAuthorizedKey.CUSTOMKEYS] : isCustomKey
    }

    if(filterFunctions[k]){
      filteredData = organizationBasedKeys?.[0]?.keys?.filter((data:any)=>filterFunctions[k](data?.reference || ""))
    }
   setKeysData([...filteredData])
  }

  const onTabClickHandler = (e: any, key: string) => {
     filterKeysHandler(key)
  }

  const tabWithKeys = (): TabItem[] => {
    const items: TabItem[] = [];
 
    items.push({ key: renderAuthorizedKey.ALLKEYS, label: "All Keys", content: <AuthorizedKeyCard data={keysData} /> });
 
    let customKeysAdded = false;
    organizationBasedKeys?.[0]?.keys?.forEach((data: any) => {
      if (isCustomKey(data?.reference || "") && !customKeysAdded) {
        items.push({ key: renderAuthorizedKey.CUSTOMKEYS, label: "Custom Keys", content: <AuthorizedKeyCard data={keysData} /> });
        customKeysAdded = true;
      }
    });
 
    let iceKeysAdded = false;
    organizationBasedKeys?.[0]?.keys?.forEach((data: any) => {
      if (isICEKey(data?.reference || "") && !iceKeysAdded) {
        items.push({ key: renderAuthorizedKey.ICEKEYS, label: "ICE Keys", content: <AuthorizedKeyCard data={keysData} /> });
        iceKeysAdded = true;
      }
    });
 
    let mobKeysAdded = false;
    organizationBasedKeys?.[0]?.keys?.forEach((data: any) => {
      if (isMOBKey(data?.reference || "") && !mobKeysAdded) {
        items.push({ key: renderAuthorizedKey.MOBKEYS, label: "MOB Keys", content: <AuthorizedKeyCard data={keysData} /> });
        mobKeysAdded = true;
      }
    });
 
    return items;
  };
  
  return <>
    <main className="hid-fixed-container__main">
      <Typography
        variant={TypographyVariantEnum.ProfileHeader}
        className="hid-spacing__mb-05"
      >
        {t("READERS.AUTHORIZEDKEYS")}
      </Typography>

      <Card>
        <div data-testid="authorizedKeys-tab">
          
          <Tabs
            theme="frameless"
            items={ tabWithKeys() }
            onClick={(e,key) => onTabClickHandler(e,key)}
          />
        </div>

      </Card>
    </main>
  </>
}

export default AuthorizedKey;