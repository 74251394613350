import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { addLogoutListener } from "./utils/apiServices";
import { loginPath } from "./utils/routes";
import { useDispatch } from "react-redux";
import { resetStateToDefault } from "./reducers/applyTemplateReducer";
import { resetFWStateToDefault } from "./reducers/upgradeFirmwareReducer";
import { resetLoginInfo, setIsSessionExpired } from "./reducers/userInfoReducer";
import { clearReaders, resetReaders } from "./reducers/discoverReadersReducer";

function LogoutInterceptor() {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    addLogoutListener((message:string) => {
      dispatch(setIsSessionExpired(true))
      dispatch(resetStateToDefault());
      dispatch(resetFWStateToDefault());
      dispatch(resetLoginInfo());
      dispatch(clearReaders());
     naviagte(loginPath,{state: {type: message||"hideSessionTimeout"}});
    });
  }, []);
  return <React.Fragment></React.Fragment>;
}
export default LogoutInterceptor;
