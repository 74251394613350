import {
  Button,
  Iconography,
  Link,
  Typography,
  Loader,
  Tooltip,
  TooltipPlacements,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../utils/i18nUtils";
import DownloadGateway from "./downloadGateway";
import GateWayPanel from "./gateWayPanel";
import ReadersTable from "../../../widgets/ReadersTable";
import ReadersView from "./readersDetails";
import { IconographySizes } from "@hid-galaxy-ui/galaxy-react/components/Iconography";
import SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import Device from "../../../assets/images/device.png";
import {
  getAvailableFirmwareService,
  locateReaderService,
} from "../../../services/readerAPIService";
import { socketUrl } from "../../../utils/apiServices";
import Notification from "@hid-galaxy-ui/galaxy-react/components/Notification";
import readerIconWhite from "../assets/icons/readerIconWhite.svg";
import {
  buildNotification,
  showDeviceBusyNotification,
} from "../../../utils/notification";
import { discoverReaderService } from "../../../services/readerAPIService";
import ReaderIcon from "../assets/icons/reader.svg";
import { useDispatch, useSelector } from "react-redux";
import "./_readers.scss";
import {
  loginInfoData,
  resetLoginInfo,
  setGlobalToasts,
  setSelectedGateway,
  setShowGatewayUpgradeModal,
} from "../../../reducers/userInfoReducer";
import {
  clearReaders,
  discoveredReadersData,
  resetReaders,
  setDiscoveryLoading,
  setPageFrom,
  setReaders,
  setSelectedReaderRow,
} from "../../../reducers/discoverReadersReducer";
import { useLocation, useNavigate } from "react-router-dom";

import MultipleSessionModal from "../../../widgets/MultiplSessionModal";
import {
  isUSBReader,
  readerNotificationCloseDelay,
} from "../../../utils/commonConst";
// import { connectToStomp, stompClient, unSubscribe } from "../../../utils/SocketInit";
import { useStompClient } from "../../../utils/useStompClient";
import { loginPath } from "../../../utils/routes";
import { callLogoutService } from "../../../services/loginAPIService";
import {
  resetNotification,
  resetSelectedReadersForApplyConfig,
  resetStateToDefault,
} from "../../../reducers/applyTemplateReducer";
import { resetFWStateToDefault } from "../../../reducers/upgradeFirmwareReducer";
import LatestGatewayUpgradeModal from "../../../widgets/LatestGatewayUpgradeModal";
// let topic: { id: string; unsubscribe: () => void };
// var stompClient: Stomp.Client;
export interface ITableData {
  operationAllowed: boolean;
  id: string;
  fwDisplayName: string;
  dcid?: string;
  readerType: string;
  readerName: string;
  controllerName: string;
  lastUpdatedDate: string;
  serialNumber?: string;
  fwUpdateRequired?: boolean;
  port?: string;
  restrictionReason?: string;
}

export interface ICoulmnData {
  dataField: string;
  headerLabel: string;
  cellSize: number;
  type: string;
  isExpandable: boolean;
}
export enum renderPageEnum {
  READERS_TABLE = "READERS_TABLE",
  TABLE_DETAILS = "TABLE_DETAILS",
}
export enum deviceDiscoveryEnum {
  DEVICE_DISCOVERY_SUCCESS = "DISCOVERY_SUCCESS",
  DEVICE_DISCOVERY_FAILED = "DISCOVERY_FAILED",
}
const deviceTypeMapper = {
  HID_SIGNO_READER: "HID Signo",
  // Add more mappings as needed
};

export default function ReadersPage() {
  const { t } = useTranslation(NAMESPACE.READER);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [existingSession, setExistingSession] = useState(
    (state && state.existingSession) || 0
  );
  const navigation = useNavigate();

  const onLogout = async () => {
    try {
      await callLogoutService();
      localStorage.clear();
      dispatch(resetStateToDefault());
      dispatch(resetFWStateToDefault());
      dispatch(resetNotification());
      dispatch(resetLoginInfo());
      dispatch(setPageFrom("list"));
      dispatch(clearReaders());
      navigation(loginPath,{state: {type: "hideSessionTimeout"}});
    } catch {}
  };

  useEffect(() => {
    if (state && state.existingSession) {
      window.history.replaceState({}, document.title);
    }
  }, [state && state.existingSession]);
  const {
    selectedCustomerInfo,
    showOnPremInstaller,
    selectedGateway,
    showGatewayUpgradeModal,
  } = useSelector(loginInfoData);
  const { dicoveredReaders, pageFrom, selectedReaderRow, discoveryLoading } =
    useSelector(discoveredReadersData);
  const customerId = selectedCustomerInfo?.customerId?.toString();
  // const [discoveryLoading, setDiscoveryLoading] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<ITableData>();
  const [showDownloadGateway, setShowDownloadGateway] = useState<boolean>(true);
  const [showOnPremDownload, setShowOnPremDownload] =
    useState<boolean>(showOnPremInstaller);
  const [renderPage, setRenderPage] = useState<string>(
    pageFrom === "carousel"
      ? renderPageEnum.TABLE_DETAILS
      : renderPageEnum.READERS_TABLE
  );

  const [locateReaderStatus, setLocateReaderStatus] = useState<string | null>(
    null
  );
  const [locatedreader, setLocatedreader] = useState<string>();
  const [toasts, setToasts] = useState([]) as any;
  const [selectedGatewayDevice, setSelectedGatewayDevice] = useState(
    selectedGateway || ""
  );
  const [modifiedData, setModifiedData] = useState<any>([]);
  const [prevReader, setPrevReader] = useState<any>({});
  const [prevSelectedReader, setPrevSelectedReader] = useState("");

  const readersData =
    (dicoveredReaders &&
      dicoveredReaders[selectedGatewayDevice] &&
      dicoveredReaders[selectedGatewayDevice].readers) ||
    null;
  const timestamp =
    (dicoveredReaders &&
      dicoveredReaders[selectedGatewayDevice] &&
      dicoveredReaders[selectedGatewayDevice].timestamp) ||
    "";

  // on clicking browser back button it should navigate to login page
  // useEffect(() => {
  //   const handlePopState = () => {
  //     // Trigger logout only on back button click
  //     onLogout();
  //   };

  //   // Add the event listener for popstate
  //   window.addEventListener("popstate", handlePopState);
  // }, [navigation]);

  useEffect(() => {
    setSelectedGatewayDevice(selectedGateway);
    if (dicoveredReaders) {
      if (readersData) {
        const modifiedReders = [...readersData].map((row) => ({
          ...row,
          isDisabled: !row.operationAllowed,
        }));
        setModifiedData(modifiedReders);
      }
    }
  }, [selectedGateway, dicoveredReaders]);

  const [readerDiscoverError, setReaderDiscoverError] =
    useState<boolean>(false);
  const { connectToStomp, subscribe, unsubscribe } = useStompClient();

  useEffect(() => {
    selectedReaderRow && setSelectedRow(selectedReaderRow);
  }, [selectedReaderRow]);

  const onDownloadGatewayClose = () => {
    setShowDownloadGateway(false);
  };

  const onGateWayDeviceSelected = (device: string) => {
    setPrevSelectedReader(device);
    if (selectedGateway === device) {
      dispatch(setSelectedGateway(""));
      setSelectedGatewayDevice("");
    } else {
      dispatch(setSelectedGateway(device));
      setSelectedGatewayDevice(device);
    }
    setReaderDiscoverError(false);
    dispatch(setDiscoveryLoading(false));
  };
  const onReaderDiscoveryBuGateway = (device: string) => {
    setPrevSelectedReader(device);
    dispatch(setSelectedGateway(device));
    setSelectedGatewayDevice(device);
    dispatch(
      resetReaders({
        data: { readers: null, timestamp: Date.now().toString() },
        gateway: device,
      })
    );

    connect(device);
  };

  const resetStatus = () => {
    setTimeout(() => {
      setLocateReaderStatus(null);
    }, 5000);
  };

  const handleStompConnect = (deviceId: any) => {
    try {
      const topic = `/topic/${selectedCustomerInfo?.customerId}/${
        deviceId || selectedGatewayDevice
      }`;
      const discoveryTimeout = setTimeout(() => {
        console.warn(
          `No discovery callback received for ${
            deviceId || selectedGatewayDevice
          }`
        );

        unsubscribe(deviceId || selectedGatewayDevice);
        dispatch(setDiscoveryLoading(false));
        setReaderDiscoverError(true);
        setPrevReader({ ...prevReader, [prevSelectedReader]: false });
      }, 360 * 1000); // 360 seconds
      subscribe(
        topic,
        (response) => {
          if (response.body) {
            setPrevReader({ ...prevReader, [prevSelectedReader]: false });
            dispatch(setDiscoveryLoading(false));
            clearTimeout(discoveryTimeout); // Clear the timeout if a response is received
            const readersResponse = JSON.parse(response.body);
            if (
              readersResponse.status ===
                deviceDiscoveryEnum.DEVICE_DISCOVERY_FAILED &&
              readersResponse?.deviceDetails?.length === 0
            ) {

              if (readersResponse?.error == "Reader discovery requires the latest Linq Gateway version. Please upgrade.") {
                setToasts([
                  buildNotification(
                    "ErrorWarn",
                    "",
                    <a
                      onClick={() =>
                        dispatch(setShowGatewayUpgradeModal(true))
                      }
                      style={{
                        cursor: "pointer",
                        color: "#0057B7",
                        marginRight: 10,
                      }}
                    >
                      {t("READERS.VIEW_MORE")}
                    </a>,
                    readersResponse?.error
                  ),
                ]);
              }else{
                setToasts([buildNotification("Error", readersResponse?.error)]);
              }
             
              unsubscribe(deviceId || selectedGatewayDevice);
              // topic && topic.unsubscribe();
              return;
            }
            if (
              readersResponse.status ===
              deviceDiscoveryEnum.DEVICE_DISCOVERY_FAILED
            ) {
              dispatch(
                setGlobalToasts([
                  buildNotification("Error", readersResponse?.error),
                ])
              );
              if (readersResponse?.deviceDetails.length > 0) {
                let res = JSON.parse(response.body).deviceDetails;
                res.timestamp = Date.now();
                dispatch(
                  setReaders({
                    data: { readers: res, timestamp: Date.now().toString() },
                    gateway: deviceId || selectedGatewayDevice,
                  })
                );
              }
            } else {
              let res = JSON.parse(response.body).deviceDetails;
              res.timestamp = Date.now();
              dispatch(
                setReaders({
                  data: {
                    readers: JSON.parse(response.body).deviceDetails,
                    timestamp: Date.now().toString(),
                  },
                  gateway: deviceId || selectedGatewayDevice,
                })
              );
            }
            unsubscribe(deviceId || selectedGatewayDevice);
          }
        },
        deviceId || selectedGatewayDevice
      );
    } catch (err) {
      unsubscribe(deviceId || selectedGatewayDevice);
      setPrevReader({ ...prevReader, [prevSelectedReader]: false });
    }
  };
  const connect = async (deviceId?: string) => {
    try {
      const payload = {
        gatewayId: deviceId || selectedGatewayDevice,
      };
      dispatch(resetSelectedReadersForApplyConfig());
    
      await discoverReaderService(payload, customerId);
      dispatch(setDiscoveryLoading(true));

      connectToStomp(() =>
        handleStompConnect(deviceId || selectedGatewayDevice)
      );
      setPrevReader({ ...prevReader, [deviceId || prevSelectedReader]: true });
      dispatch(setDiscoveryLoading(true));
    } catch (err: any) {
      if (err.response?.status === 409) {
        if (
          err?.response?.data?.statusDescription ===
          "Specified Action could not be initiated as the device is not connected with IoT"
        ) {
          showDeviceBusyNotification(
            dispatch,
            t("READERS.OPERATION_INTERRUPTED")
          );
        } else {
          showDeviceBusyNotification(
            dispatch,
            t("READERS.NO_READERS_CONNECTED")
          );
        }
      }
      setReaderDiscoverError(true);
      setPrevReader({ ...prevReader, [prevSelectedReader]: false });
    }
  };

  const locateReader = async (readerId: string) => {
    try {
      if (customerId) {
        setLocatedreader(readerId);
        const response = await locateReaderService(
          { gatewayId: selectedGatewayDevice },
          { connectedDeviceId: readerId },
          customerId
        );

        if (response.data.status.code === 200) {
          setLocateReaderStatus("success");
          resetStatus();
        } else {
          setLocateReaderStatus("failed");
          resetStatus();
          setToasts([buildNotification("Error", response.data.status.message)]);
        }
      }
    } catch (error) {
      setLocateReaderStatus("failed");
      resetStatus();
      setToasts([buildNotification("Error", t("READERS.UNABLE_TO_LOCATE"))]);
    }
  };
  const showNotification = () => {
    return (
      <Notification
        toasts={toasts}
        setToasts={setToasts}
        isSticky={false}
        isAutoClose={false}
      >
        {toasts[0]?.content ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{toasts[0]?.data}</div>
            <div>{toasts[0]?.content || null}</div>
          </div>
        ) : null}
      </Notification>
    );
  };
  const getLocateIconClass = (id: string) => {
    if (id === locatedreader) {
      return locateReaderStatus === "success"
        ? "success-color"
        : locateReaderStatus === "failed"
        ? "filure-color"
        : "default-color";
    } else {
      return "default-color";
    }
  };
  const renderCustomCell = (_: ITableData, rowData: ITableData) => {
    return (
      <Tooltip
        message={t("READERS.LOCATE_READER")}
        placement={TooltipPlacements.Top}
      >
        <Iconography
          icon="volume"
          className={`cursor-pointer ${getLocateIconClass(rowData.id)}`}
          size={IconographySizes.Medium}
          onClick={() => locateReader(rowData.id)}
        />
      </Tooltip>
    );
  };
  const getDeviceName = (input: string) => {
    if (deviceTypeMapper.hasOwnProperty(input)) {
      return deviceTypeMapper[input as keyof typeof deviceTypeMapper];
    } else {
      return input;
    }
  };
  const renderDeviceType = (_: ITableData, rowData: ITableData) => {
    return <div>{getDeviceName(rowData.readerType)}</div>;
  };
  const renderConnectionType = (_: ITableData, rowData: ITableData) => {
    return (
      <div>
        {rowData && rowData.port && !isUSBReader(rowData?.port)
          ? t("READERS.HID_CONTROLLERS")
          : t("READERS.USB")}
      </div>
    );
  };
  const renderFirmware = (_: ITableData, rowData: ITableData) => {
    if (rowData.fwUpdateRequired) {
      return (
        <Tooltip
          message={t("READERS.FW_UPDATE_REQUIRED")}
          placement={TooltipPlacements.Top}
        >
          <div style={{ color: "#F8971D" }}>
            <span>{rowData.fwDisplayName}</span>
            <Iconography icon="circleExclamation" />
          </div>
        </Tooltip>
      );
    } else {
      return <span>{rowData.fwDisplayName}</span>;
    }
  };
  const readerEmptyPage = () => (
    <div className="hid-text-center hid-layout__mt-08">
      <div className="hid-layout__mt-05">{<img src={ReaderIcon} alt="" />}</div>
      <span className="hid-origo__gateway-title hid-layout__mt-05">
        {t("READERS.DISCOVERED_READERS")}
      </span>
      <Typography
        className="hid-layout__m-05"
        variant={TypographyVariantEnum.BodyShortProduct}
      >
        {t("READERS.EMPTY_READERS_DESC")}
      </Typography>
    </div>
  );
  const discoverReadersPage = () => (
    <div className="hid-text-center hid-layout__mt-08">
      <img
        src={Device}
        className="hid-origo__linq-reader-image"
        alt=""
        data-testid="reader-img"
      />
      {prevReader &&
      prevSelectedReader &&
      prevReader[prevSelectedReader] &&
      prevReader[prevSelectedReader] ? (
        // discoveryLoading
        <>
          <Loader />
          <Typography variant={TypographyVariantEnum.TextSmall}>
            {t("READERS.CONNECTING_TO_READERS")}
          </Typography>
        </>
      ) : (
        <Button
          label={t("READERS.DISCOVER_READERS")}
          variant="primary"
          onClick={() => connect()}
          icon={<img src={readerIconWhite} alt="" />}
        />
      )}
    </div>
  );
  const renderReaderName = (_: ITableData, rowData: ITableData) => {
    return (
      <Tooltip
        message={
          !!rowData.operationAllowed &&
          rowData &&
          rowData?.restrictionReason &&
          rowData?.restrictionReason?.length >= 1
            ? rowData && rowData?.restrictionReason
            : t("READERS.INSPECT_READER")
        }
        placement={TooltipPlacements.Top}
      >
        <Link
          className="text-left"
          disabled={
            (!!rowData.operationAllowed &&
              rowData &&
              rowData?.restrictionReason &&
              rowData?.restrictionReason?.length >= 1) ||
            rowData.fwUpdateRequired
          }
          onClick={() =>
            !rowData.fwUpdateRequired && handleActionClick(rowData)
          }
        >
          { rowData?.id}
        </Link>
      </Tooltip>
    );
  };
  const columnsData = [
    {
      dataField: "id",
      headerLabel: t("READERS.NAME"),
      component: "renderReaderName",
      cellSize: 2,
      type: "custom",
      isExpandable: true,
    },
    {
      dataField: "action",
      headerLabel: "Actions",
      clickHandler: "handleActionClick",
      cellSize: 1,
      type: "custom",
      component: "renderCustomCell",
      isExpandable: true,
    },
    {
      dataField: "dcid",
      headerLabel: t("READERS.CONFIGURATION_ID"),
      cellSize: 1,
      type: "text",
      isExpandable: true,
    },
    {
      dataField: "fwDisplayName",
      headerLabel: t("READERS.FIRMWARE"),
      cellSize: 1,
      type: "custom",
      component: "renderFirmware",
      isExpandable: true,
    },
    {
      dataField: "readerType",
      headerLabel: t("READERS.HARDWARE_MODEL"),
      cellSize: 1,
      type: "custom",
      component: "renderDeviceType",
      isExpandable: true,
    },
    {
      dataField: "port",
      headerLabel: t("READERS.CONNECTION_TYPE"),
      cellSize: 1,
      type: "custom",
      component: "renderConnectionType",
      isExpandable: true,
    },
  ];
  const handleActionClick = (rowData: ITableData) => {
    setRenderPage(renderPageEnum.TABLE_DETAILS);
    dispatch(setSelectedReaderRow(rowData));
    setSelectedRow(rowData);
  };
  const dependencies = {
    handleActionClick: handleActionClick,
    renderCustomCell: renderCustomCell,
    renderReaderName: renderReaderName,
    renderDeviceType: renderDeviceType,
    renderFirmware: renderFirmware,
    renderConnectionType: renderConnectionType,
  };
  const backToReaderListPage = () => {
    setRenderPage(renderPageEnum.READERS_TABLE);
    dispatch(setPageFrom("list"));
    // connect();
  };
  const renderView = () => {
    switch (renderPage) {
      case renderPageEnum.READERS_TABLE:
        return (
          <div data-testid="readers">
            <Typography variant={TypographyVariantEnum.H1}>
              {t("READERS.SCREEN_HEADING")}
            </Typography>

            <div className="hid-grid hid-layout__mt-04 w-100">
              <div className="hid-grid__column hid-grid__column--4-xs hid-grid__column--4-lg">
                <GateWayPanel
                  onGateWayDeviceSelected={onGateWayDeviceSelected}
                  selectedgatewayDevice={selectedGatewayDevice}
                  onReaderDiscoveryBuGateway={onReaderDiscoveryBuGateway}
                  prevReader={prevReader}
                />
              </div>

              <div className="hid-grid__column hid-grid__column--8-xs ">
                {readersData ? (
                  <ReadersTable
                    dependencies={dependencies}
                    data={modifiedData}
                    columnsData={columnsData}
                    timestamp={timestamp}
                  />
                ) : selectedGatewayDevice ? (
                  discoverReadersPage()
                ) : (
                  readerEmptyPage()
                )}
              </div>
              <div className="hid-grid__column hid-grid__column--8-xs"></div>
            </div>
          </div>
        );
      case renderPageEnum.TABLE_DETAILS:
        return (
          <ReadersView
            selectedRow={selectedRow}
            rowData={readersData || []}
            locateReaderStatus={locateReaderStatus}
            onLocateReader={locateReader}
            selectedGatewayDevice={selectedGatewayDevice}
            backToReaderList={backToReaderListPage}
          />
        );
      default:
        return null;
    }
  };

  return (
    <main className="hid-fixed-container__main">
      <>
        <Typography
          variant={TypographyVariantEnum.ProfileHeader}
          className="hid-spacing__mb-05"
        >
          {renderPage === renderPageEnum.TABLE_DETAILS
            ? t("READERS.SCREEN_HEADING")
            : t("READERS.HID_LINQ")}
        </Typography>

        {/* {showOnPremDownload && (
          <OnPremDownload onCloseClick={onPremDownloadClose} />
        )} */}
        {showDownloadGateway && renderPage === renderPageEnum.READERS_TABLE ? (
          <DownloadGateway onCloseClick={onDownloadGatewayClose} />
        ) : null}
        {renderView()}
      </>
      {toasts.length > 0 && showNotification()}
      {existingSession > 0 && (
        <MultipleSessionModal
          closeModal={() => {
            setExistingSession(false);
            dispatch(setShowGatewayUpgradeModal(true));
          }}
        />
      )}{" "}
      {showGatewayUpgradeModal && (
        <LatestGatewayUpgradeModal
          onClose={() => dispatch(setShowGatewayUpgradeModal(false))}
        />
      )}
    </main>
  );
}
