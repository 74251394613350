import React from "react";
import { Button, Header, Iconography, IconographySizes, Typography } from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import OrigoFooter from "./widgets/OrigoFooter";
import './ErrorBoundary.scss';

interface Props {
    children : React.ReactNode
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Props,State> {
    constructor(props:Props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error:Error) {
      return { hasError: true };
    }
  
    componentDidCatch(error:Error, errorInfo:React.ErrorInfo) {
      console.error("Error Boundary Caught Error : ",error, errorInfo);
    }
   
    onTryAgainClickHandler(){
      localStorage.clear();
      window.location.href = '/';
    }

    render() {
      if (this.state.hasError) {
        // Rendering custom error component
        return <>

          <div className="header">
            <Header title="Linq" />
          </div>
          <div className="container">
            <Iconography icon="triangleExclamation" size={IconographySizes.XLarge} className="warningSymbol" /> <br />
            <Typography
              variant={TypographyVariantEnum.H1}
              className="hid-flex hid-flex-jc-center hid-flex-ai-center hid-full-width"
            >
              Something Went Wrong ! </Typography>
            <br />
            <Button onClick={this.onTryAgainClickHandler} className="tryAgainBtn" normalCase label="Try Again" variant="secondary" />
          </div>
          <OrigoFooter />
        </>
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;