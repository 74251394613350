import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetStateToDefault, resetNotification } from "../../reducers/applyTemplateReducer";
import { setPageFrom, clearReaders } from "../../reducers/discoverReadersReducer";
import { resetFWStateToDefault } from "../../reducers/upgradeFirmwareReducer";
import { resetLoginInfo } from "../../reducers/userInfoReducer";
import { callLogoutService } from "../../services/loginAPIService";
import { loginPath } from "../../utils/routes";

const UnauthorizedRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const onLogout = async () => {
    try {
      localStorage.clear()
      dispatch(resetStateToDefault());
      dispatch(resetFWStateToDefault());
      dispatch(resetNotification());
      dispatch(resetLoginInfo());
      dispatch(setPageFrom("list"));
      dispatch(clearReaders());
      navigate(loginPath);
    } catch {}
  };

  useEffect(() => {
    onLogout()
  }, []);

  return null;
};

export default UnauthorizedRedirect;
