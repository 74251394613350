import { Modal, ModalVariants, Typography } from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
interface ILatestGatewayUpgradeModal {
  onClose: () => void;
}
export default function LatestGatewayUpgradeModal(
  props: ILatestGatewayUpgradeModal
) {
  const { onClose } = props;
  return (
    <Modal size={ModalVariants.Large} onClose={onClose}>
      <div className="gateway-upgrade-container">
        <Typography variant={TypographyVariantEnum.ProfileHeader}>
          Attention required!
        </Typography>{" "}
        <Typography variant={TypographyVariantEnum.BodyLongProduct}>
          A new version of Linq Gateway (v1.xx) is now available. Please note
          that any previously installed versions of the Gateway are no longer
          supported and will not work
        </Typography>
        <br />
        <strong>
          <Typography variant={TypographyVariantEnum.BodyLongProduct}>
            If an earlier version of Linq Gateway is already installed on this
            machine, follow the instruction given below:
          </Typography>
        </strong>
        <Typography variant={TypographyVariantEnum.BodyLongProduct}>
          <ul>
            <li>Uninstall the existing Gateway version.</li>{" "}
            <li>Download and install the latest Gateway version v1.2.</li>
          </ul>
        </Typography>
        <strong>
          <Typography variant={TypographyVariantEnum.BodyLongProduct}>
            If you do not have Linq Gateway installed on this machine before,
          </Typography>
        </strong>
        <Typography variant={TypographyVariantEnum.BodyLongProduct}>
          You can directly download and install Gateway version v1.xx to start
          performing device operations without any additional steps.
        </Typography><br/>
        <Typography variant={TypographyVariantEnum.BodyLongProduct}>
          Thank you for your understanding and cooperation.
        </Typography>
      </div>
    </Modal>
  );
}
