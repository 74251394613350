import {
  Button,
  Iconography,
  IconographySizes,
  NotificationVariants,
  ProgressBar,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import "./applyTemplateNotification.scss";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFIrmwareResponse,
  resetFWStateToDefault,
  setUpgradeFirmwareProgressModalOpen,
  upgradeFirmwareData,
} from "../../reducers/upgradeFirmwareReducer";
import { useEffect, useMemo, useState } from "react";
import {
  removeRunningProcesses,
  setNotification,
  setSelectedCorrelationId,
} from "../../reducers/applyTemplateReducer";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { countRunningProcesses } from "../../utils/commonConst";
interface INotificationWithProgress {
  isCompleted: boolean;
  updateFirmwareResponse: any;
  runningProcesses: any;
  handleLeftArrowClick: any;
  handleRightArrowClick: any;
  setNotificationContent: (
    data: {
      id: string;
      icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    }[]
  ) => void;
  selectedCorrelationId: string;
}
export default function NotificationWithProgress(
  props: INotificationWithProgress
) {
  const status = props?.updateFirmwareResponse[props?.selectedCorrelationId] && props?.updateFirmwareResponse[props?.selectedCorrelationId][0] && props?.updateFirmwareResponse[props?.selectedCorrelationId][0]['updateFirmwareOverallStatus'] && props?.updateFirmwareResponse[props?.selectedCorrelationId][0] && props?.updateFirmwareResponse[props?.selectedCorrelationId][0]['updateFirmwareOverallStatus'] || null;
  const [OverallfirmwareStatus,setOverallFirmwareStatus ] = useState(status);
  const {
    updateFirmwareResponse = [],
    runningProcesses,
    handleLeftArrowClick,
    handleRightArrowClick,
    setNotificationContent,
    selectedCorrelationId,
  } = props;
  const { t } = useTranslation(NAMESPACE.READER);
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const { isUpgradeFirmwareProgressModalOpen } =
    useSelector(upgradeFirmwareData);
  const isDeviceDisconnected =
    (selectedCorrelationId &&
      runningProcesses?.[`${selectedCorrelationId}`]?.deviceDisconnected) ||
    false;

  // maintaining this state and useEffect because border color and status - icon was not updating . 
  useEffect(()=>{
    if(status && status){
      setOverallFirmwareStatus(status)
    }
  },[status])

  useEffect(() => {
    if (isDeviceDisconnected) {
      dispatch(
        setNotification([
          {
            id: "1",
            icon: <Iconography icon="circleX" />,
            variant: NotificationVariants.Error,
          },
        ])
      );
    }
  }, [dispatch, isDeviceDisconnected]);
  const hasCompletedUpgrade = Object.values(runningProcesses).some(
    (process: any) => process.type === "upgrade" && process.isCompleted
  );
  const allProcessCompleted = (processes: any) =>
    Object.values(processes).every((process: any) => process.isCompleted);
  const isPartialSuccess = () => {
    for (const [group, items] of Object.entries(updateFirmwareResponse)) {
      const itemsArray = items as any[];

      // const failedCount = itemsArray.filter(
      //   (c) =>
      //     c.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_FAILED" ||
      //     c.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_SYNC_FAILED"
      // );
      const successCount = itemsArray.filter(
        (c) => c.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_SUCCESS"
      );

      if (successCount.length > 0) {
        return true;
      }
    }
    return false;
  };
  const isAllSuccess = () => {
    for (const [group, items] of Object.entries(updateFirmwareResponse)) {
      const itemsArray = items as any[];

      const failedCount = itemsArray.filter(
        (c) =>
          c.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_FAILED" ||
          c.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_SYNC_FAILED"
      );
      if (failedCount.length == 0) {
        return true;
      }
    }
    return false;
  };

  const finalStatus = useMemo(() => {
      if (allProcessCompleted(runningProcesses)) {
        if (isDeviceDisconnected) {
          return "deviceDisconnected";
        } else if (isAllSuccess()) {
          return "success";
        } else if (isPartialSuccess()) {
          return "partialSuccess";
        } else {
          return "failure";
        }
      }
      return "inProgress";
    }, [runningProcesses, isDeviceDisconnected, isAllSuccess, isPartialSuccess]);

  const statusToIconMapper = useMemo(
    () => ({
      failure: {
        icon: <Iconography icon="circleX" />,
        variant: NotificationVariants.Error,
      },
      deviceDisconnected: {
        icon: <Iconography icon="circleX" />,
        variant: NotificationVariants.Error,
      },
      success: {
        icon: <Iconography icon="circleCheck" />,
        variant: NotificationVariants.Success,
      },
      partialSuccess: {
        icon: <Iconography icon="triangleExclamation" />,
        variant: NotificationVariants.Warning,
      },
      inProgress: {
        icon: <Iconography icon="arrowsRepeat" />,
        variant: NotificationVariants.Info,
      },
    }),
    []
  );

  useEffect(() => {
    if (finalStatus) {
      dispatch(
        setNotification([
          {
            id: "1",
            icon: statusToIconMapper[finalStatus]?.icon,
            variant: statusToIconMapper[finalStatus]?.variant,
          },
        ])
      );
    }
  }, [finalStatus, selectedCorrelationId, dispatch, OverallfirmwareStatus ]);
  const removeProcess = (correlationId: string) => {
    if (Object.keys(runningProcesses)?.length === 1) {
      setNotificationContent([]);
    } else {
      if (
        countRunningProcesses(runningProcesses) > 1 &&
        Object.keys(updateFirmwareResponse).length === 1
      ) {
        handleRightArrowClick();
      } else {
        const processIds = Object.keys(runningProcesses);
        let upgradeProcessIds = [];

        // Collect all process IDs of type "upgrade"
        for (const processId of processIds) {
          if (runningProcesses[processId].type === "upgrade") {
            upgradeProcessIds.push(processId);
          }
        }

        // get the process ID except the current process ID
        const nextId = upgradeProcessIds.find((id) => id !== correlationId);
        nextId && dispatch(setSelectedCorrelationId(nextId));
      }
    }

    dispatch(removeRunningProcesses(correlationId));
    dispatch(removeFIrmwareResponse(correlationId));
  };
  const handleClearAllClick = () => {
    const keysWithCompletedTrue = Object.keys(runningProcesses).filter(
      (key) => runningProcesses[key].isCompleted === true
    );
    const hasUpgradeType = Object.values(runningProcesses).some(
      (entry: any) => entry.type === "upgrade"
    );
    const updateTypeReader = Object.values(runningProcesses).filter(
      (entry: any) => entry.type === "upgrade"
    );
  
    if (
      countRunningProcesses(runningProcesses) > 1 &&
      Object.keys(updateFirmwareResponse).length === 1
    ) {
      if (!hasUpgradeType) {
        handleRightArrowClick();
      }
    } else {
      if (
        Object.keys(runningProcesses)?.length === 1 ||
        Object.keys(runningProcesses)?.length === updateTypeReader.filter((c:any)=>c.isCompleted).length
      ) {
        setNotificationContent([]);
      } else {
        const processIds = Object.keys(runningProcesses);
        let upgradeProcessIds = [];

        // Collect all process IDs of type "upgrade"
        for (const processId of processIds) {
          if (runningProcesses[processId].type === "upgrade") {
            upgradeProcessIds.push(processId);
          }
        }

        // get the process ID except the  process ID present in keysWithCompletedTrue
        const newId = upgradeProcessIds.filter(
          (id) => !keysWithCompletedTrue.includes(id)
        );
        newId.length >= 1 && dispatch(setSelectedCorrelationId(newId[0]));
      }
    }
    keysWithCompletedTrue.map((process: any) => {
      dispatch(removeRunningProcesses(process));
      dispatch(removeFIrmwareResponse(process));
    });
  };

  // useEffect(() => {
  //   if (!isUpgradeFirmwareProgressModalOpen) {
  //     if (runningProcesses && allProcessCompleted(runningProcesses)) {
  //       const keysWithCompletedTrue = Object.keys(runningProcesses).filter(
  //         (key: any) =>
  //           runningProcesses[key].isCompleted === true &&
  //           runningProcesses[key].type === "upgrade"
  //       );

  //       if (Object.keys(runningProcesses)?.length > 1) {
  //         handleRightArrowClick();
  //       } else {
  //         setNotificationContent([]);
  //       }

  //       keysWithCompletedTrue.map((process: any) => {
  //         dispatch(removeRunningProcesses(process));
  //       });
  //       dispatch(resetFWStateToDefault());
  //     }
  //   }
  // }, [isUpgradeFirmwareProgressModalOpen]);
  const getGroupData = (readerResponse: any, type: string) => {
    const failedCount = readerResponse.filter(
      (c: any) =>
        c.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_FAILED" ||
        c.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_SYNC_FAILED"
    );
    const successCount = readerResponse.filter(
      (c: any) => c.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_SUCCESS"
    );
    const queueCount = readerResponse.filter(
      (c: any) => c.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_QUEUED"
    );
    const updatingCount = readerResponse.filter(
      (c: any) =>
        c.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_IN_PROGRESS"
    );

    switch (type) {
      case "progressClass":
        if (queueCount?.length > 0 || updatingCount?.length > 0) {
          return "hid-progress-bar__indicator--info";
        } else if (successCount.length === readerResponse.length) {
          return "hid-progress-bar__indicator--success";
        } else if (failedCount.length === readerResponse.length) {
          return "hid-progress-bar__indicator--danger";
        }
        break;
      case "progressText":
        if (queueCount?.length > 0 || updatingCount?.length > 0) {
          return t("READERS.IN_PROGRESS");
        } else if (failedCount.length > 0) {
          return `${failedCount.length} ${t("READERS.FAILED_SMALL")}`;
        } else {
          return t("READERS.COMPLETED_SMALL");
        }
        break;

      default:
        break;
    }
  };
  const getAveragePreogress = (readers: any) => {
    const totalPreogress = readers.reduce(
      (total: any, current: any) => total + current.fwUpdateProgressPercentage,
      0
    );
    return totalPreogress / readers.length;
  };
  return (
    <>
      <div className="hid-grid hid-spacing__p-04 hid-spacing__pr-01">
        <div
          style={{ alignItems: "center" }}
          className={`hid-grid ${
            countRunningProcesses(runningProcesses) > 1
              ? "hid-grid__column--7-xs"
              : "hid-grid__column--10-xs"
          }`}
        >
          <div className="hid-grid__column hid-grid__column--11-xs">
            <div
              className="hid-flex"
              style={{ justifyContent: "space-between" }}
            >
              <Typography variant={TypographyVariantEnum.Label}>
                {allProcessCompleted(runningProcesses)
                  ?isAllSuccess()?"Firmware updated successfully": isPartialSuccess()
                    ? "Firmware update completed with partial success"
                    : "Firmware updated failed"
                  : t("READERS.UPDATING_FIRMWARE")}
              </Typography>

              {Object.keys(updateFirmwareResponse).length > 0 && (
                // !allProcessCompleted(runningProcesses) &&
                <div
                  onClick={() => setShowMore(!showMore)}
                  className="blue-text"
                >
                  {showMore ? "Show Less" : "Show More"}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="hid-grid hid-grid__column--3-xs hid-grid--end-xs ">
          <Button
            label="VIEW"
            onClick={() => dispatch(setUpgradeFirmwareProgressModalOpen(true))}
          />
        </div>

        {countRunningProcesses(runningProcesses) > 1 ? (
          <div className="hid-grid__column hid-grid__column--2-xs hid-grid__column--order-end-xs hid-text-right arrow-container hid-spacing__ml-06 hid-flex-ai-center">
            <Iconography
              icon="circleChevronLeft"
              size={IconographySizes.Medium}
              className={
                Object.keys(runningProcesses).length > 1
                  ? "default-color"
                  : "disabled-color"
              }
              onClick={handleLeftArrowClick}
            />
            <Iconography
              icon="circleChevronRight"
              size={IconographySizes.Medium}
              className={
                Object.keys(runningProcesses).length > 1
                  ? "default-color"
                  : "disabled-color"
              }
              onClick={handleRightArrowClick}
            />
          </div>
        ) : null}
      </div>
      {showMore && Object.keys(updateFirmwareResponse).length > 0 && (
        // !allProcessCompleted(runningProcesses) &&
        <div className="upgrade-firmware-content">
          {hasCompletedUpgrade && (
            <div
              className="clear-all"
              onClick={handleClearAllClick}
              style={{ cursor: "pointer" }}
            >
              Clear All
            </div>
          )}
          <div className="progress-container">
             {/**.filter(([group, items], groupIndex) => */}
            {Object.entries(updateFirmwareResponse)
              .filter(([group]) =>
                Object.keys(runningProcesses).includes(group)
              ).sort(([groupA], [groupB]) => {
                // Sort based on the runningProcesses[group]?.name
                const nameA = runningProcesses[groupA]?.name || "";
                const nameB = runningProcesses[groupB]?.name || "";
                return nameA.localeCompare(nameB);
              })
              .map(([group, items], groupIndex) => {
                return (
                  <div className="hid-grid hid-spacing__mt-03">
                    <div className="hid-grid__column hid-grid__column--5-xs">
                   <>
                   {/* {console.log('items', items)} */}
                   {/*{`${runningProcesses[group]?.name} (${*/}
                   {/** old logic */}
                      {`${runningProcesses[group]?.name || "Unknown Group"} (${
                        (items as any[])?.length
                      } Readers)`}</>
                    </div>
                    <div
                      className="hid-grid__column hid-grid__column--5-xs hid-flex"
                      style={{ justifyContent: "flex-end" }}
                    >
                      {getGroupData(items, "progressText")}
                    </div>
                    {(items as any).filter(
                      (group: any) =>
                        group.updateFirmwareOverallStatus ===
                          "DEVICE_FW_UPGRADE_IN_PROGRESS" ||
                        group.updateFirmwareOverallStatus ===
                          "DEVICE_FW_UPGRADE_QUEUED"
                    ).length === 0 ? (
                      <div className="hid-grid__column hid-grid__column--2-xs hid-flex hid-flex-ai-end hid-flex-jc-center cursor-pointer">
                        <Iconography
                          icon="xmark"
                          size={IconographySizes.Small}
                          onClick={() => removeProcess(group)}
                        />
                      </div>
                    ) : null}
                    <div className="hid-grid__column hid-grid__column--10-xs">
                      <ProgressBar
                        progress={getAveragePreogress(items)}
                        progressBarColorClass={
                          getGroupData(items, "progressClass") || ""
                        }
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
