import React from "react";
import { Typography } from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { hasHidAdminRole } from "./utils/linq_utils";
import { USER_ROLES } from "./utils/commonInterfaces/IFormField";
import UnauthorizedRedirect from "./widgets/UnauthorizedRedirect";
import { useSelector } from "react-redux";
import { loginInfoData } from "./reducers/userInfoReducer";

interface IRoute {
  component: React.ElementType;
  layout?: any;
  isProtected?: boolean;
  allowedRole?: USER_ROLES;
}

const RouteComponent = (props: IRoute) => {
  const {
    component: Component,
    layout: Layout,
    allowedRole,
    isProtected,
  } = props;

  const { isUserLoggedIn,userInfo } = useSelector(loginInfoData);
  // const isAuthenticated = isUserLoggedIn();
  const validateRoute = () => {
    return allowedRole && hasHidAdminRole(allowedRole);
  };
  const isUserLoggedIntoSession = () => {
    return isUserLoggedIn;
  }  
  const renderProtectedComponent = () => {
    if(isUserLoggedIntoSession() && !userInfo?.acceptedTOS){
      return <UnauthorizedRedirect />;
    }
    if (isUserLoggedIntoSession() && allowedRole ) {
    
      return validateRoute() ? (
        RenderComponent()
      ) : (
        <>
          <Typography
            className="hid-text-center"
            variant={TypographyVariantEnum.ProfileHeader}
          >
            You Don't have permisson for access this page
          </Typography>
          <Typography
            className="hid-text-center"
            variant={TypographyVariantEnum.H1}
          >
            UI is under development
          </Typography>
        </>
      );
    } else if (isUserLoggedIntoSession()) {
      return RenderComponent();
    } else {
      return <UnauthorizedRedirect />;
    }
  };

  const RenderComponent = () =>
    Layout ? (
      <Layout>
        <Component />
      </Layout>
    ) : (
      <Component />
    );
  return isProtected ? renderProtectedComponent() : RenderComponent();
};

export default RouteComponent;
